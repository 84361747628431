<script>
export default {
  data() {
    return {
      display_sidebar: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    getIMGPath(img) {
      return img ? require("@/assets/" + img) : "";
    },
    toggleDiv() {
      this.display_sidebar = !this.display_sidebar;
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
  },
};
</script>
<template>
  <nav :class="{ close: display_sidebar }">
    <div>
      <div class="logo-name">
        <div class="logo-image">
          <img :src="getIMGPath('Untitled-2-12.svg')" alt="" />
        </div>

        <span class="logo_name">CMS</span>
      </div>
      <div class="menu-items">
        <div v-if="currentUser">
          <ul class="nav-links">
            <!-- 
                <li><a @click="navigate" @keypress.enter="navigate" role="link">
                    <i class="uil uil-files-landscapes"></i>
                    <span class="link-name">
                    <router-link to="/admin" custom v-slot="{ navigate }">
                            <span>Rejestr szkoleń</span>
                        </router-link>
                    </span>
                </a></li>

                <li><a @click="navigate" @keypress.enter="navigate" role="link">
                    <i class="uil uil-thumbs-up"></i>
                    <span class="link-name">Firmy</span>
                </a></li>
                <li><a @click="navigate" @keypress.enter="navigate" role="link">
                    <i class="uil uil-comments"></i>
                    <span class="link-name">
                    <router-link to="/course" custom v-slot="{ navigate }">
                            <span>Szkolenia</span>
                        </router-link>
                    </span>
                </a></li>
                <li><a @click="navigate" @keypress.enter="navigate" role="link">
                    <i class="uil uil-share"></i>
                    <span class="link-name">Parametry</span>
                </a></li> -->
            <li>
              <router-link to="/dash" custom v-slot="{ navigate }">
                <a @click="navigate" @keypress.enter="navigate" role="link">
                  <i class="uil uil-estate"></i>
                  <span class="link-name">
                    <span>Panel główny</span>
                  </span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/employee" custom v-slot="{ navigate }">
                <a @click="navigate" @keypress.enter="navigate" role="link">
                  <i class="uil uil-user-square"></i>
                  <span class="link-name">
                    <span>Pracownicy</span>
                  </span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/todoItems" custom v-slot="{ navigate }">
                <a @click="navigate" @keypress.enter="navigate" role="link">
                  <i class="uil uil-apps"></i><!-- bylo uil uil-list-ul -->
                  <span class="link-name">
                    <span>Zadania</span>
                  </span>
                </a>
              </router-link>
            </li>
            <li>
              <router-link to="/media" custom v-slot="{ navigate }">
                <a @click="navigate" @keypress.enter="navigate" role="link">
                  <i class="uil uil-images"></i>
                  <span class="link-name">
                    <span>Media</span>
                  </span>
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <ul class="logout-mode">
          <li>
            <div v-if="!currentUser">
              <router-link to="/login" custom v-slot="{ navigate }">
                <a @click="navigate" @keypress.enter="navigate" role="link">
                  <i class="uil uil-signin"></i>
                  <span class="link-name">Zaloguj się</span>
                </a>
              </router-link>
            </div>
            <div v-else>
              <router-link to="/login" custom v-slot="{ navigate }">
                <a @click="logout" @keypress.enter="navigate" role="link">
                  <i class="uil uil-signout"></i>
                  <span class="link-name">Wyloguj się</span>
                </a>
              </router-link>
            </div>

          </li>
          <!-- <li class="mode">
            <a @click="navigate" @keypress.enter="navigate" role="link">
              <i class="uil uil-moon"></i>
              <span class="link-name">Dark Mode</span>
            </a>
            <div class="mode-toggle">
              <span class="switch"></span>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>

  <section class="dashboard">
    <div class="top">
      <i class="uil uil-bars sidebar-toggle" v-on:click="toggleDiv()"></i>
      <!-- <div class="search-box">
        <i class="uil uil-search"></i>
        <input type="text" placeholder="Szukaj..." />
      </div> -->
      <div v-if="!currentUser">Panel administratora</div>
      <div v-else> Witamy {{ currentUser.userName }}</div>
      <!-- <img :src="getIMGPath('logo.png')" alt="" /> -->
    </div>
    <section class="main">
      <router-view></router-view>
    </section>
  </section>
</template>
<style>
.main {
  margin-top: 70px;
}

a {
  cursor: pointer !important;
}
</style>
