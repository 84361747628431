export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  const API_KEY =  process.env.VUE_APP_API_KEY;
  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token ,
    'XApiKey': API_KEY}; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {'XApiKey': API_KEY};
    //return {};params: { mail: xyx@example.col }, //Add mail as a param
  }
}
