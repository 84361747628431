<template>
        <!-- <section class="dashboard"> -->
        <div class="dash-content">
            <div class="overview">
                <div class="title">
                    <!-- <i class="uil uil-tachometer-fast-alt"></i> -->
                    <i class="uil uil-comments"></i>
                    <span class="text">Panel główny</span>
                </div>

                <div class="boxes">
                    <div class="box box1">
                        <i class="uil uil-thumbs-up"></i>
                        <span class="text">Wszystkie szkolenia</span>
                        <span class="number">50,120</span>
                    </div>
                    <div class="box box2">
                        <i class="uil uil-comments"></i>
                        <span class="text">Zakończone szkolenia</span>
                        <span class="number">20,120</span>
                    </div>
                    <div class="box box3">
                        <i class="uil uil-share"></i>
                        <span class="text">Ilość użytkowników</span>
                        <span class="number">10,120</span>
                    </div>
                </div>
            </div>

            <div class="activity">
                <div class="title">
                    <!-- <i class="uil uil-clock-three"></i> -->
                    <i class="uil uil-share"></i>
                    <span class="text">Aktywność</span>
                </div>

                <div class="activity-data">
                    <div class="data names">
                        <span class="data-title">Nazwa użytkownika</span>
                        <span class="data-list">Marek Łysol</span>
                        <span class="data-list">Paweł Niski</span>
                        <span class="data-list">Łukasz Żuk</span>
                        <span class="data-list">Małgorzata Kowal</span>
                        <span class="data-list">Łucja Głowacka</span>
                        <span class="data-list">Mirosław Wesoły</span>
                        <span class="data-list">Wiesława Kowalska</span>
                    </div>
                    <div class="data email">
                        <span class="data-title">Email</span>
                        <span class="data-list">premshahi@gmail.com</span>
                        <span class="data-list">deepachand@gmail.com</span>
                        <span class="data-list">prakashhai@gmail.com</span>
                        <span class="data-list">manishachand@gmail.com</span>
                        <span class="data-list">pratimashhai@gmail.com</span>
                        <span class="data-list">manshahi@gmail.com</span>
                        <span class="data-list">ganeshchand@gmail.com</span>
                    </div>
                    <div class="data joined">
                        <span class="data-title">Data</span>
                        <span class="data-list">2024-09-12</span>
                        <span class="data-list">2024-09-12</span>
                        <span class="data-list">2024-09-13</span>
                        <span class="data-list">2024-09-13</span>
                        <span class="data-list">2024-09-14</span>
                        <span class="data-list">2024-09-14</span>
                        <span class="data-list">2024-09-15</span>
                    </div>
                    <div class="data type">
                        <span class="data-title">Rodzaj aktywności</span>
                        <span class="data-list">Rejestracja</span>
                        <span class="data-list">Rozpoczęcie szkolenia</span>
                        <span class="data-list">Zakończenie szkolenia</span>
                        <span class="data-list">Logowanie</span>
                        <span class="data-list">Rozpoczęcie szkolenia</span>
                        <span class="data-list">Rejestracja</span>
                        <span class="data-list">Logowanie przez email</span>
                    </div>
                    <div class="data status">
                        <span class="data-title">Status</span>
                        <span class="data-list">Zakończone</span>
                        <span class="data-list">W trakcie</span>
                        <span class="data-list">Zakończone</span>
                        <span class="data-list">Zakończone</span>
                        <span class="data-list">Zakończone</span>
                        <span class="data-list">Zakończone</span>
                        <span class="data-list">Nieudane</span>
                    </div>
                </div>
            </div>
        </div>
    <!-- </section> -->
</template>
<style>
.dashboard .dash-content{
    /* padding-top: 50px; */
}
.dash-content .title{
    display: flex;
    align-items: center;
    margin: 0px 0 30px 0;
}
.dash-content .title i{
    position: relative;
    height: 35px;
    width: 35px;
    background-color: var(--primary-color);
    border-radius: 6px;
    color: var(--title-icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.dash-content .title .text{
    font-size: 24px;
    font-weight: 500;
    color: var(--text-color);
    margin-left: 10px;
}
.dash-content .boxes{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0px 0 30px 0;
}
.dash-content .boxes .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    width: calc(100% / 3 - 15px);
    padding: 15px 20px;
    background-color: var(--box1-color);
    transition: var(--tran-05);
}
.boxes .box i{
    font-size: 35px;
    color: var(--text-color);
    cursor: pointer;
}
.boxes .box .text{
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
}
.boxes .box .number{
    font-size: 40px;
    font-weight: 500;
    color: var(--text-color);
}
.boxes .box.box2{
    background-color: var(--box2-color);
}
.boxes .box.box3{
    background-color: var(--box3-color);
}
.dash-content .activity .activity-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.activity .activity-data{
    display: flex;
}
.activity-data .data{
    display: flex;
    flex-direction: column;
    margin: 0 15px;
}
.activity-data .data-title{
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
}
.activity-data .data .data-list{
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    white-space: nowrap;
    color: var(--text-color);
}
</style>