import axios from 'axios';
import authHeader from './auth-header';
const API_URL =  process.env.VUE_APP_BASE_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'Authentication/login1', {
        username: user.username,
        password: user.password
      }, { headers: authHeader() })
      .then(response => {
        if (response.data.token) 
        {
          localStorage.setItem('user', JSON.stringify(response.data));
          //TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem('user');
  }
  register(user) {
    return axios
    .post(API_URL + 'Authentication/register', {
      username: user.username,
      email: user.email,
      password: user.password,
      userlastname: user.username,
      company: user.username,
      position: user.username,
      typeposition: user.password
    });
  }
}

export default new AuthService();
