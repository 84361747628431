import AuthService from '../services/auth.service';
//import jwt_decode from 'jwt-decode';
//var decode = require('jwt-claims');
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };
 // function parseJwt (token) {
if (initialState.status.loggedIn)
{user.claims = JSON.parse(Buffer.from(user.token.split('.')[1], 'base64').toString());
user.userName = user.claims.Surname +" "+ user.claims.Name;
// user.userToDo = 1;
} 
//}
//const decoded = jwt_decode(token);
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      user.claims = JSON.parse(Buffer.from(user.token.split('.')[1], 'base64').toString());
      user.userName = user.claims.Surname +" "+ user.claims.Name;
      //user.userToDo = 2;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
