import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./fonts/inter/inter.css";
import "./styles/global.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Dodaj wszystkie ikony z darmowego zestawu solid icons
library.add(fas);

createApp(App)
  .use(router)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount("#app");
