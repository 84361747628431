<template>
  <div id="app">
    <Admin></Admin>
  </div>
</template>

<script>
import Admin from "./components/Admin";
export default {
    components: {
      Admin
  },
};
</script>
<style>
.dialog-open{
  overflow: hidden;
}
</style>